<template>
  <div class="abort-real ">
    <div v-for="(item, index) in showList.content" :key="index" class="abort-real-content ">
      <!-- 左侧布局 -->
      <div v-if="item.direction == 'left'" class="content_left pc-type">
        <div class="img_content_left">
          <img :src="item.img" alt="" />
        </div>
        <div class="text_content">
          <div v-for="(items,indexs) in item.list_content" :key="indexs">
            <span v-if="items.type != 'is_list'" :class="items.type == 'is_blue'?'is_blue':items.type=='is_b_title'?'is_b_title':items.type == 'is_l_title'?'is_l_title':items.type == items.is_content? 'is_content':''"  v-show="items.text !='' ">{{items.text}}</span>
            <span v-if="items.type == 'is_list'"    v-show="items.text !='' "><li>{{items.text}}</li></span>
          </div>
          <div class="download p_" v-if="showList.setting.type==true" @click="openLink()">下载RealPlayer<img src="@/img/new_download.svg" /></div>
        </div>
      </div>
      <!-- 右侧布局 -->
      <div v-if="item.direction == 'right'" class="content_righ pc-type">
        
        <div class="text_content">
          <div v-for="(item1,index1) in item.list_content" :key="index1">
            <span v-if="item1.type != 'is_list'" :class="item1.type == 'is_blue'?'is_blue':item1.type=='is_b_title'?'is_b_title':item1.type == 'is_l_title'?'is_l_title':item1.type == item1.is_content? 'is_content':''"  v-show="item1.text !='' ">{{item1.text}}</span>
            <span v-if="item1.type == 'is_list'"    v-show="item1.text !='' "><li>{{item1.text}}</li></span>
          </div>
          <div class="download p_" v-if="showList.setting.type==true" @click="openLink()">下载RealPlayer<img src="@/img/new_download.svg" alt=""></div>
        </div>

        <div class="img_content_right">
          <img :src="item.img" alt="" />
        </div>
      </div>
      <!-- 手机端布局 -->
      <div class="content_left mobile-type">
        <div class="img_content_left">
          <img :src="item.img" alt="" />
        </div>
        <div class="text_content">
          <div v-for="(item3,index3) in item.list_content" :key="index3+1">
            <span v-if="item3.type != 'is_list'" :class="item3.type == 'is_blue'?'is_blue':item3.type=='is_b_title'?'is_b_title':item3.type == 'is_l_title'?'is_l_title':item3.type == item3.is_content? 'is_content':''"  v-show="item3.text !='' ">{{item3.text}}</span>
            <span v-if="item3.type == 'is_list'"    v-show="item3.text !='' "><li>{{item3.text}}</li></span>
          </div>
          <div class="download p_" @click="openLink()">下载RealPlayer<img src="@/img/new_download.svg" /></div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: "abort-real",
  props: {
    showList: {
      type: Object,
    },
  },
  methods:{
    openLink:function(url,type=false){
         this.$router.push(`/thankyou?from=${this.showList.setting.page}`);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (min-width: 480px) {
  .mobile-type{display: none !important;}
  /* 字体 */
  .is_blue{font-size: 20px;font-weight: 700;color: #00a7ff;opacity: 1;}
  .is_b_title{letter-spacing: 0;color: #000;opacity: 1;font-size: 28px;font-weight: 700;}
  .is_l_title{font-size: 16px;font-weight: 700;letter-spacing: 0;color: #000;line-height: 35px;}
  .is_list{font-size: 16px;line-height: 1.428571429;color: #333;}
  
  .abort-real-content{width: 100%;}
  .abort-real-content>div{display: flex;justify-content: space-between;align-items: center;padding:40px 10%;}
  /* 内容颜色 */
  .content_left{background: #fff !important;}
  .content_right{background:transparent linear-gradient(180deg,#f4f4f4 0,#eaeaea 100%) 0 0 no-repeat padding-box;}
  /* 图片位置 */
  .img_content_left{width: 50%;display: flex;justify-content: center;align-items: left;}
  .img_content_right{width: 50%;display: flex;justify-content: center;align-items: right;}
  .text_content{width:50%;}
  .text_content>div{margin: 8px 0;}
  .download{font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    color: #00a7ff;
    opacity: 1;
    margin-top: 30px !important;
  }
  .download img{    height: 15px;
    width: auto;}
  .abort-real {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .pc-type{display: none !important;}
  .abort-real {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
    /* 字体 */
    .is_blue{font-size: 20px;font-weight: 700;color: #00a7ff;opacity: 1;}
    .is_b_title{letter-spacing: 0;color: #000;opacity: 1;font-size: 28px;font-weight: 700;}
    .is_l_title{font-size: 16px;font-weight: 700;letter-spacing: 0;color: #000;line-height: 35px;}
    .is_list{font-size: 16px;line-height: 1.428571429;color: #333;}
  
  .abort-real-content{width: 100%;
    padding: 0 6%;
    background: #fff;
  }
  /* 内容颜色 */
  .content_left{background: #fff !important;}
  .content_right{background:transparent linear-gradient(180deg,#f4f4f4 0,#eaeaea 100%) 0 0 no-repeat padding-box;}
  /* 图片位置 */
  .img_content_left{display: flex;justify-content: center;align-items: left;}
  .img_content_left img{display: block;width: 100%;}
  .text_content{width:100%;}
  .text_content>div{margin: 8px 0;}
  .download{font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    color: #00a7ff;
    opacity: 1;
    margin-top: 30px !important;
  }
  .download img{    height: 15px;
    width: auto;}
}
</style>
