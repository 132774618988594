<template>
  <div class="abort-real">
    <div v-for="(item, index) in showList" :key="index">
      <img :src="item.img" alt="" />
      <div class="abort-title">{{ item.title }}</div>
      <div class="abort-content">{{ item.content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "abort-real",
  props: {
    showList: {
      type: Array,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (min-width: 480px) {
  .abort-real {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .abort-real > div {
    width: 45%;
    box-sizing: border-box;
    padding-bottom: 50px;
  }
  img {
    height: 60px;
  }
  .abort-title {
    font: bold 23px Red Hat Display !important;
    letter-spacing: 0;
    color: #000;
    opacity: 1;
    display: block;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .abort-content {
    text-align: justify !important;
    line-height: 32px;
    font-size: 15px;
    font-weight: 400;
    font-weight: "Red Hat Display" !important;
    color: #333;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .abort-real {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .abort-real > div {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 50px;
  }
  img {
    height: 60px;
  }
  .abort-title {
    font: bold 23px Red Hat Display !important;
    letter-spacing: 0;
    color: #000;
    opacity: 1;
    display: block;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .abort-content {
    text-align: justify !important;
    line-height: 32px;
    font-size: 15px;
    font-weight: 400;
    font-weight: "Red Hat Display" !important;
    color: #333;
  }
}
</style>
