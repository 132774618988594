<template>
  <div class="Realplayer">
    <!-- 头部问题及banner -->
    <div class="banner" :style="{backgroundImage:'url(' + bannerBack + ')'}">
      <div class="t-c c-b text1">RealPlayer 22</div>
      <div class="t-c text2">最好的PC播放器！</div>
      <download-btn from="realplayer" style="margin-bottom:79px;"></download-btn>
    </div>

    <!-- 内容介绍部分 -->
    <div class="content">
      <abort-real-content :show-list="list"></abort-real-content>
      <div class="t-c botton-title">立刻下载RealPlayer 电脑版</div>
      <download-btn from="realplayer"></download-btn>
      <div class="t-c p_ text4 " @click="openFeatures()">查看所有功能 ›</div>
      <div class="all-features" v-if="features_type">
        <div><img src="@/img/realplayer_dui.png" alt=""> 一键下载数千网站视频！</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 投屏让您拥有剧场版沉浸式体验！</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 通过RealPlayer移动版随时随地访问您的媒体库。</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 播放和刻录DVD*</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 强大的文件管理</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 私人保险箱让您的视频更安全*</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 视频文件格式转换</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 删除重复视频*</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 视频编辑让视频分享更容易</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 自动识别CD标签</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> 音频增强控制器*</div>
        <div><img src="@/img/realplayer_dui.png" alt=""> *部分需要订购尊享版</div>
      </div>
    </div>

    <!-- 底部mobile模块 -->
    <div class="mobile-show">
      <div class="t-c mobile-show-title">使用 RealPlayer 移动版完善您的体验</div>
      <!-- 文字模块 -->
      <div class="mobile-show-content">
        <!-- 左侧模块 -->
        <div class="msc-l">
          <div class="c-b text5">新版本！</div>
          <div class="text6">RealPlayer 移动版</div>
          <div class="p_ text7" @click="openLink('/realplayer-mobile',true)">了解更多 ›</div>
          <div class="text8">RealPlayer 的必备伴侣。随时随地访问您的 RealPlayer 媒体库、同步和离线观看视频、投射到大屏幕、将您的视频备份到 RealPlayer Cloud 等等！</div>
          <div class="scancode pc-type">
            <div class="scancode-google" @click="openLink(toGoogleLink)">
                <img src="@/img/googleplay.svg" alt="">
                <img src="@/img/googleplay_QR.png" alt="">
            </div>
            <div class="scancode-appstore"  @click="openLink(toAppStoreLink)">
                <img src="@/img/appstore.svg" alt="">
                <img src="@/img/appstore_QR.svg" alt="">
            </div>
          </div>
          <div class="scancode mobile-type">
            <div class="scancode-google" @click="openLink(toGoogleLink)"  v-if="type == 'andriod'">
                <img src="@/img/googleplay.svg" alt="">
                <img src="@/img/googleplay_QR.png" alt="">
            </div>
            <div class="scancode-appstore"  @click="openLink(toAppStoreLink)"  v-if="type == 'ios'">
                <img src="@/img/appstore.svg" alt="">
                <img src="@/img/appstore_QR.svg" alt="">
            </div>
          </div>
          <div class="t-c text9">使用照相手机扫描二维码，安装应用程序</div>
        </div>
        <!-- 右侧图片 -->
        <div class="msc-r">
          <img src="@/img/mobile-phone.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AbortReal from "@/components/AbortReal.vue"
  import AbortRealContent from "@/components/RealContent.vue"
  import DownloadBtn from "@/components/DownloadBtn.vue"
  import settingLink from '@/js/global.js'
  export default {
   name: 'Realplayer',
    data(){
      return{
        toGoogleLink:settingLink.googleLink,
        toAppStoreLink:settingLink.appStoreLink,
        bannerBack:require('@/img/new_realpleyer.png'),
        features_type:false, // 隐藏查看所有功能
        // 模块展示内容
        list:{
          setting:{
            page:'realplayer',
            type:true,// 是否带下载
          },
          content:[
            {
              img:require('@/img/new_realpleyer01.png'),
              direction:'left',// 图片在左or右 left  right
              list_content:[
                {text:'全新！',type:'is_blue', },
                {text:'更强大的视频下载器！',type:'is_b_title', },
                {text:'随心下载',type:'is_l_title', },
                {text:"选项更多，自定义下载分辨率、下载速度",type:'is_content', },
                {text:"或只下载音频",type:'is_content', },
                {text:"支持数千视频网站",type:'is_l_title', },
                {text:'立即播放',type:'is_l_title', },
                {text:"在RealPlayer播放器中直接播放网页视频",type:'is_content', },
                {text:"优先热修复",type:'is_l_title', },
                {text:"*该功能仅限尊享用户",type:'is_content', },
              ]
            },
            {
              img:require('@/img/new_realpleyer02.png'),
              page:'real',
              type:true,// 是否带下载
              direction:'right',// 图片在左or右 left  right
              list_content:[
                {text:'全新！',type:'is_blue', },
                {text:'从数千个站点下载视频，一键完成！',type:'is_b_title', },
                {text:"从数千个站点下载视频，只需一键完成！ 边看边下。畅享不限速，画质高达 4K。使用 RealPlayer 移动版标记手机上的视频，它们将自动添加到您的电脑媒体库中。",type:'is_content', },
              ]
            },
            {
              img:require('@/img/new_realpleyer04.png'),
              page:'real',
              type:true,// 是否带下载
              direction:'right',// 图片在左or右 left  right
              list_content:[
                {text:'全新！',type:'is_blue', },
                {text:'在大屏幕上欣赏您的视频！',type:'is_b_title', },
                {text:"播放任意视频格式。使用 “投屏功能” 将您的视频内容直接投屏到电视上。在沙发上欣赏巨幕影像。",type:'is_content', },
                {text:"RealPlayer 支持 CD 和 DVD 播放和刻录。",type:'is_content', },
              ]
            }
          ]},
        // 当前设备类型
        type:'', // pc/andriod/ios
      }
    },
    components: {
      AbortReal,DownloadBtn,AbortRealContent
    },
    created:function(){
      window.scrollTo(0,0);
      var type = window.localStorage.getItem('type'); // 获取当前设备类型
      this.type = type;
    },
    methods:{
      openLink:function(url,type=false){
        if(type){
           this.$router.push(url);
        }else{
          window.open(url,'_blank');
        }
      },
      openFeatures:function(){
        this.features_type = !this.features_type
      }
      
    }
}
</script>
<style scoped>
@media screen and (min-width: 480px) {
  /* 新增样式 */
  .all-features{width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;margin: 0 auto;}
  .all-features>div{padding-left:40%;box-sizing: border-box;width: 100%;display: flex;justify-content: flex-start;align-items: center;color: #333;font-size: 16px;font-weight: 400;font-stretch: normal;margin-top: 14px;}
  .all-features img{width: 20px;height: 20px;margin-right: 4px;}
  .botton-title{    
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    color: #000;
    margin: 20px;
  }
  /* 新增样式 */
  .mobile-type{display: none !important;}
  .text1{font: bold 32px Red Hat Display!important;color: #ffbe00 !important;}
  .text2{font: bold 45px Red Hat Display!important;color: #fff !important;}
  .text3{font: normal 14px Red Hat Display!important;color: #525252;}
  .text4{font: normal bold 14px/18px Red Hat Display;padding-top:20px;margin-top: 20px;}
  .text4:hover{color: #337ab7;}
  .text5{font: bold 16px Red Hat Display!important;padding-top: 18px;color:#ffbe00 !important;}
  .text6{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;color: #fff;}
  .text7{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px;color: #ffbe00 !important;}
  .text8{font: normal 15px Red Hat Display!important;color: #ffffff;}
  .text9{font: normal 15px Red Hat Display!important;color: #ffffff;padding-top: 26px;}
  .banner{
    height: 250px;
    box-sizing: border-box;
    text-align: center;
    background-size: 100% 250px; 
    background-repeat: no-repeat	;
    padding-top: 20px;
  }
  .content{
    background: transparent linear-gradient(180deg,#f8f8f8 0,#eaeaea 100%) 0 0 no-repeat padding-box;
    padding-bottom: 40px;
  }

  .mobile-show{margin:0 auto;padding-top:25px;padding: 25px 16%;background: #0098f1;}
  .mobile-show-title{
    font: bold 40px Red Hat Display!important;
    color: #fff;
    padding-bottom: 20px;
  }
  .mobile-show-content{
    display: flex;
    justify-content: space-between;
  }
  .msc-l{
    flex:1;
  }
  .msc-r img{max-height: 433px!important;}

  /* 二维码 */
  .scancode{display: flex;justify-content: space-evenly;}
  .scancode-google{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-google img:nth-child(2){width: 125px;height: 125px;}
  .scancode-appstore{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-appstore img:nth-child(2){width: 125px;height: 125px;}
}
@media screen and (min-width: 320px) and (max-width:480px){
  .all-features{width: 80%;display: flex;flex-direction: column;justify-content: center;align-items: center;margin: 0 auto;}
  .all-features>div{width: 100%;display: flex;justify-content: flex-start;align-items: center;color: #333;font-size: 16px;font-weight: 400;font-stretch: normal;margin-top: 14px;}
  .all-features img{width: 16px;height: 16px;margin-right: 4px;}

  .pc-type{display: none !important;}
  .text1{font: bold 14px Red Hat Display!important;color:#ffbe00 !important;padding-top: 20px !important;}
  .text2{font: bold 22px Red Hat Display!important;color:#fff !important;}
  .text3{font: normal 14px Red Hat Display!important;color: #525252;}
  .text4{font: normal bold 14px/18px Red Hat Display;padding-top:20px;}
  .text4:hover{color: #337ab7;}
  .text5{font: bold 16px Red Hat Display!important;padding-top: 18px;color:#ffbe00 !important;}
  .text6{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;color: #fff;}
  .text7{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px;color: #ffbe00!important;}
  .text8{font: normal 15px Red Hat Display!important;color: #ffffff;}
  .text9{font: normal 15px Red Hat Display!important;color: #ffffff;padding-top: 26px;}
  .banner{
    height: 158px;width: 100%;margin: 0 auto;
    padding: 0 5%;
    box-sizing: border-box;
    text-align: center;
    background-size: 100% 158px; 
    background-repeat: no-repeat	;
  }
  .content{
    background: transparent linear-gradient(180deg,#f8f8f8 0,#eaeaea 100%) 0 0 no-repeat padding-box;
    padding-bottom: 40px;
  }
  .mobile-show{max-width:730px;margin:0 auto;padding-top:25px;padding: 25px 16%;background: #0098f1;}

  .mobile-show-title{
    font: bold 20px Red Hat Display!important;
    color: #fff;
    padding-bottom: 20px;
  }
  .msc-l{
    flex:1;
  }
  .msc-r img{max-height: 233px!important;display: block;margin: 0 auto;}

  /* 二维码 */
  .scancode{display: flex;justify-content: space-evenly;}
  .scancode-google{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-google img:nth-child(2){width: 125px;height: 125px;}
  .scancode-appstore{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-appstore img:nth-child(2){width: 125px;height: 125px;}
}
  

</style>