<template>
  <div class="download-btn">
    <div @click="openLink()">免费下载</div>
  </div>
</template>

<script>
export default {
  name: "download-btn",
  props:['from'],
  watch:{
    'from':function(newVal){
      console.lgo(newVal)
      this.from = newVal
    }
  },
  methods:{
    openLink:function(url,type=false){
         this.$router.push(`/thankyou?from=${this.from}`);
    },
  }
};
</script>
<style scoped>

@media screen and (min-width: 480px) {
  .download-btn {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: center;
  }
  .download-btn div {
    width: 380px;
    height: 64px;
    line-height: 64px;
    display: block;
    text-align: center;
    background: #ffbe00 0 0 no-repeat padding-box;
    border-radius: 40px !important;
    font-size: 23px;
    cursor: pointer;
    color: #000 !important;
  }
}
@media screen and (min-width: 320px) and (max-width:480px){
    .download-btn {
      padding: 20px 0 0 0;
      display: flex;
      justify-content: center;
    }
    .download-btn div {
      width: 90%;
      height: 34px;
      line-height: 34px;
      display: block;
      text-align: center;
      background: #ffbe00 0 0 no-repeat padding-box;
      border-radius: 40px !important;
      font-size: 16px;
      cursor: pointer;
      color: #000 !important;
    }
}
</style>
